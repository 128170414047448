import { RootState } from 'src/types/State'
import { Interval } from 'src/backend/enums'
import { WebConfig } from 'src/types/WebConfig'

export const selectWebConfig = (state: RootState): WebConfig => state.modules.webConfig
export const selectDefaultInterval = (state: RootState): Interval => selectWebConfig(state).interval
export const selectShowDecimalPlaces = (state: RootState): boolean => selectWebConfig(state).showDecimalPlaces
export const selectHideMobileAppBanner = (state: RootState): boolean => selectWebConfig(state).hideMobileAppsBanner
export const selectAdvertisementHiddenAt = (state: RootState): string => selectWebConfig(state).advertisementHiddenAt
export const selectVisitedWebV2 = (state: RootState): boolean => selectWebConfig(state).visitedWebV2
