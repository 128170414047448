import React from 'react'
import Carousel, { ViewType } from "react-images"
import Gallery from "src/frontend/scenes/records/components/Gallery"
import { Photo } from "src/types/Record"
import { Modal } from "semantic-ui-react"

interface Props {
  photos?: Photo[]
}

interface State {
  lightboxIsOpen: boolean
  currentImageIndex: number
}

class Photos extends React.Component<Props, State> {
  static defaultProps = {
    photos: [],
  }

  state: State = {
    lightboxIsOpen: false,
    currentImageIndex: 0,
  }

  openLightBox = (photosCount) => (event, index) => {
    event.preventDefault()
    if (index > photosCount - 1) {
      // TODO (edit photos) there will be action to open upload file dialog here
    } else {
      this.setState({
        currentImageIndex: index,
        lightboxIsOpen: true,
      })
    }
  }

  closeLightbox = () => {
    this.setState({
      currentImageIndex: 0,
      lightboxIsOpen: false,
    })
  }

  render() {
    const { photos } = this.props
    const { currentImageIndex, lightboxIsOpen } = this.state

    if (photos) {
      // TODO (edit photos) add image representing add photo button here
      // const photosWithAddPhoto = [ ...photos, { url: addPhotoImage } ]

      const carouselPhotos: Array<ViewType> = photos.map((photo) => ({
        ...photo,
        source: photo.url,
      }))
      const galleryPhotos = photos.map((photo) => ({
        ...photo,
        src: photo.url,
      }))

      return (
        <>
          <Gallery
            photos={galleryPhotos}
            onPhotoClick={this.openLightBox(photos.length)}
          />
          <Modal
            open={lightboxIsOpen}
            size="large"
            basic
            onClose={this.closeLightbox}
          >
            {lightboxIsOpen ? (
              <Carousel
                views={carouselPhotos}
                currentIndex={currentImageIndex}
                styles={{
                  view: () => ({
                    maxHeight: "80vh",
                    position: "relative",
                    textAlign: "center",
                  }),
                }}
              />
            ) : null}
          </Modal>
        </>
      )
    }

    return null
  }
}

export default Photos
