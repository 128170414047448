import React, { useEffect, useState } from "react"
import defaultProfileImage from "src/frontend/assets/images/user_ic.svg"
import { Form, Input } from "semantic-ui-react"
import { FormattedMessage } from "src/frontend/modules/intl"
import { User } from "src/types/User"
import styles from "src/frontend/scenes/auth/softLogin/SoftLoginPage.module.less"
import { buildLocalizedErrorMessage } from "src/frontend/scenes/auth/helpers"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"
import PasswordField from "src/frontend/scenes/auth/components/PasswordField"
import FormattedMessageLabel from "src/frontend/modules/intl/components/FormattedMessageLabel"
import { convertPhotoUrlToPublicUrl } from "src/common/utils"

interface Props {
  user: User
  onBackToLoginPage: (MouseEvent) => void
  onLoginSubmit: (email: string, password: string) => void
}

export default function SoftLoginForm({ user, onBackToLoginPage, onLoginSubmit }: Props) {
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [profileImage, setProfileImage] = useState<string>(defaultProfileImage)

  let imgElement

  const handleLoginSubmit = async () => {
    try {
      setLoading(true)
      await onLoginSubmit(user.email, password)
    } catch (error) {
      setError(buildLocalizedErrorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    let isMounted = true

    async function convertAvatar() {
      /*
      try {
        if (user.avatarUrl) {
          const publicUrl = await convertPhotoUrlToPublicUrl(user.avatarUrl)
          if (isMounted && publicUrl) {
            setProfileImage(publicUrl)
          } else {
            setProfileImage(defaultProfileImage)
          }
        } else {
          // No avatar URL => fallback to default
          setProfileImage(defaultProfileImage)
        }
      } catch {
        // In case of failure => fallback to default
        setProfileImage(defaultProfileImage)
        }*/
      setProfileImage(defaultProfileImage)
    }

    convertAvatar()

    return () => {
      isMounted = false
    }
  }, [user.avatarUrl]) // re-run if user's avatar changes

  return (
    <div className={styles.softLoginForm}>
      <div>
        <img
          /* tslint:disable-next-line:jsx-use-translation-function */
          alt="Profile picture"
          className={styles.userImage}
          src={profileImage}
          ref={(img) => (imgElement = img)}
          onError={() => (imgElement.src = defaultProfileImage)}
        />
      </div>
      <div>{user.email}</div>
      <Form>
        <Input
          name="user"
          type="hidden"
          value={user.email}
          className={styles.hidden}
        />
        <PasswordField
          name="password"
          label={<FormattedMessageLabel id="auth.password" />}
          value={password}
          error={error}
          autoComplete="current-password"
          onChange={(_e, { value }) => setPassword(value)}
        />
        {error && (
          <div className="error-message">
            <FormattedMessage id={error} />
          </div>
        )}
        <PrimaryButton
          type="submit"
          fluid
          loading={loading}
          disabled={loading}
          onClick={handleLoginSubmit}
        >
          <FormattedMessage id="auth.login_button" />
        </PrimaryButton>
      </Form>
      <div
        className={styles.differentUser}
        onClick={onBackToLoginPage}
      >
        <FormattedMessage id="auth.login.different-user-oauth" />
      </div>
    </div>
  )
}
