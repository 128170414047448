import "src/frontend/assets/css/wallet-icons.css"
import React from "react"
import { connect } from "react-redux"
import { withIntlConsumer } from "src/frontend/modules/intl/components/withIntlConsumer"
import { UserLoginStatus } from "src/frontend/modules/user/enums"
import withTracker from "src/frontend/scenes/app/withTracker"
import LoadingPage from "./LoadingPage"
import ErrorMessageModal from "../../Error/components/ErrorMessageModal"
import InfoMessageModal from "src/frontend/scenes/app/InfoMessageModal"
import * as appSelectors from "./selectors"
import * as userSelectors from "src/frontend/modules/user/selectors"
import { initApp, logoutSoft, setTodayFilters } from "./actions"
import SessionExpiredModal from "src/frontend/modules/modals/components/UserSessionModal"
import styles from "./App.module.less"
import PublicContent from "./PublicContent"
import PrivateContent from "./PrivateContent"
import { withRouter } from "react-router-dom"
import { User } from "src/types/User"
import { selectIntegrationModalOpen } from "src/frontend/scenes/integrations/newConnection/selectors"
import { RouteComponentProps } from "react-router"
import { getUser } from "src/frontend/modules/user/actions"
import { selectShouldShowOnBoarding } from "src/frontend/scenes/onBoarding/firstRun/selectors"
import SoftLoginPage from "src/frontend/scenes/auth/softLogin/SoftLoginPage"
import { selectShowDecimalPlaces } from "src/frontend/modules/webConfig/selectors"
import "./globalStyles.less"
import IdleActivityModal from "src/frontend/modules/modals/components/IdleActivityModal"
import NewAppModal from "./NewAppModal"
import { isAppBoard } from "src/common/environment"

interface Props extends RouteComponentProps<{}> {
  loading: boolean
  showDecimals: boolean
  appInitialized: boolean
  loadingText: string
  showOnBoarding: boolean
  user: User
  errorMessageId: string | string[]
  errorRecoverable?: boolean
  warnBeforeLeaveMessageId?: string
  initApp: Function
  logout: (MouseEvent) => void
  changeShowDecimals: Function
  getUser: Function
  handleSetTodayFilters: Function
}

const mapStateToProps = (state) => ({
  loading: appSelectors.selectAppLoading(state),
  loadingText: appSelectors.selectAppLoadingText(state),
  appInitialized: appSelectors.selectAppSyncInitialized(state),
  showOnBoarding: selectShouldShowOnBoarding(state),
  user: userSelectors.selectUser(state),
  errorMessageId: appSelectors.selectError(state).errorMessageId,
  errorRecoverable: appSelectors.selectError(state).errorRecoverable,
  warnBeforeLeaveMessageId: appSelectors.selectWarnBeforeLeaveRouteMessageId(state),
  showDecimals: selectShowDecimalPlaces(state),
  integrationsModalOpen: selectIntegrationModalOpen(state),
})

const mapDispatchToProps = {
  getUser,
  initApp,
  handleSetTodayFilters: setTodayFilters,
  logout: logoutSoft,
}

class App extends React.Component<Props> {
  static defaultProps = {
    errorMessageId: null,
    errorRecoverable: true,
    warnBeforeLeaveMessageId: null,
  }

  constructor(props) {
    super(props)
    this.props.initApp()
  }

  componentDidMount() {
    // Initialize state for decimal places
    this.props.changeShowDecimals(this.props.showDecimals)
  }

  componentDidUpdate(prevProps) {
    // Update decimal places if state changes
    if (prevProps.showDecimals !== this.props.showDecimals) {
      this.props.changeShowDecimals(this.props.showDecimals)
    }
  }

  render() {
    const {
      loading,
      loadingText,
      appInitialized,
      showOnBoarding,
      user,
      location,
      errorMessageId,
      errorRecoverable,
      warnBeforeLeaveMessageId,
      handleSetTodayFilters,
    } = this.props

    if (user.loginStatus === UserLoginStatus.EXPIRED) {
      return (
        <div className={styles.wrapper}>
          <SoftLoginPage />
        </div>
      )
    }

    return (
      <div className={styles.wrapper}>
        <SessionExpiredModal onClick={this.props.logout} />
        {user.loginStatus === UserLoginStatus.AUTHENTICATED && <IdleActivityModal />}
        {errorMessageId && !errorRecoverable && (
          <ErrorMessageModal
            messageId={errorMessageId}
            reloadButton
            closeable={false}
          />
        )}
        {errorMessageId && errorRecoverable && (
          <ErrorMessageModal
            messageId={errorMessageId}
            reloadButton
            okButton
          />
        )}
        {/*
        {!loading && !isAppBoard() && !(localStorage.getItem("new_app_promo_hide") === "true") && (
          <NewAppModal />
        )}
          */}
        <InfoMessageModal />
        <LoadingPage
          isLoading={loading}
          loadingText={loadingText}
        >
          {/* FIXME: Fix !loading condition, because PrivateContent was being constructed here */}
          {user.loginStatus === UserLoginStatus.AUTHENTICATED ? (
            <PrivateContent
              getUser={this.props.getUser}
              onSetTodayFilters={handleSetTodayFilters}
              user={user}
              appInitialized={appInitialized}
              showOnBoarding={showOnBoarding}
              warnBeforeLeaveMessageId={
                location.pathname === "/imports" && warnBeforeLeaveMessageId
              }
            />
          ) : (
            <PublicContent />
          )}
        </LoadingPage>
      </div>
    )
  }
}

export default withRouter(
  withTracker(withIntlConsumer(connect(mapStateToProps, mapDispatchToProps)(App))),
)
