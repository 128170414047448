 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

import { AppFlavor } from 'src/common/enums'
import { parseIntDecimal } from 'src/common/utils'
import { getUserLanguage } from 'src/backend/db/localStorage'

const ENV_CONFIG_URL = '/environment.cfg'

export function isProduction() {
    return process.env.NODE_ENV === 'production'
}

export function isTest() {
    return process.env.NODE_ENV === 'test'
}

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

export function getVersion() {
    return process.env.VERSION
}

export function getAppFlavor(): AppFlavor {
    return AppFlavor[process.env.APP_FLAVOR] !== undefined
      ? (AppFlavor[process.env.APP_FLAVOR] as any)
      : AppFlavor.WALLET
}

export function isAppBoard(): boolean {
    return getAppFlavor() === AppFlavor.BOARD
}

export function getAppName(): string {
    return isAppBoard() ? 'Board' : 'Wallet'
}

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

let envVariables

export function initEnvVariables() {
    return fetch(ENV_CONFIG_URL)
        .then((response) => {
            if (!response.ok) {
                throw new Error(`Unable to fetch initial configuration from ${ENV_CONFIG_URL}`)
            } else {
                return response
            }
        })
        .then((response) => response.text())
        .then((responseText) => JSON.parse(atob(responseText)))
        .then((responseJson) => (envVariables = responseJson))
        .catch((error) => {
            if (isProduction()) {
                throw error
            } else {
                console.error('initEnvVariables failed', error)
            }
        })
}

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

// Exported probably only for testing
export function setEnvVariables(variables) {
    envVariables = variables
}

function getValue(key, defaultValue) {
    const value = envVariables && envVariables[key]
    if (value !== undefined) {
        return value
    } else if (isProduction()) {
        // TODO lets start with logging errors otherwise it should throw Error instead returning bullsh*t
        // eslint-disable-next-line no-console
        console.log(
            `Profile is '${process.env.NODE_ENV}' and the value '${key}' is not translated. Is missing '${key}' environment value?`
        )
        return `__value_missing_${key}__`
    } else {
        return defaultValue
    }
}

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

const FALSY_VALUES = ['n', 'no', 'false', '0']

function getBooleanValue(key, defaultValue) {
    const value = getValue(key, defaultValue)

    if (typeof value === 'boolean') {
        return value
    } else if (typeof value === 'string') {
        return !FALSY_VALUES.includes(value.toLowerCase())
    } else {
        return !!value
    }
}

function getNumberValue(key, defaultValue) {
    return parseIntDecimal(getValue(key, defaultValue))
}

/*
 * Beginning of parameters declaration
 * WARNING: These parameters names are parsed by Bash script therefore it should be only in this form and must be on
 * on line
 */

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

export function getTOSContentUrl() {
    const lang: string = getUserLanguage()
    let walletTOSUrl: string = ''
    switch (lang) {
        case 'it':
            walletTOSUrl = 'https://budgetbakers.com/termini-del-servizio/'
            break
        case 'es-es':
            walletTOSUrl = 'https://budgetbakers.com/condiciones-de-servicio/'
            break
        case 'fr':
            walletTOSUrl = 'https://budgetbakers.com/conditions-dutilisation-des-services'
            break
        case 'de':
            walletTOSUrl = 'https://budgetbakers.com/nutzungsbedingungen'
            break
        default:
            walletTOSUrl = 'https://budgetbakers.com/terms/'
            break
    }
    return isAppBoard() ? 'https://board.budgetbakers.com/terms-of-service/' : walletTOSUrl // BOARD does not have translated TOS
}

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

export function getPrivacyPolicyUrl() {
    const lang: string = getUserLanguage()
    let walletPolicyUrl: string = ''
    switch (lang) {
        case 'it':
            walletPolicyUrl = 'https://budgetbakers.com/politica-sulla-sicurezza'
            break
        case 'es-es':
            walletPolicyUrl = 'https://budgetbakers.com/politica-de-privacidad/'
            break
        case 'fr':
            walletPolicyUrl = 'https://budgetbakers.com/politique-de-confidentialite'
            break
        case 'de':
            walletPolicyUrl = 'https://budgetbakers.com/datenschutzinformationen'
            break
        default:
            walletPolicyUrl = 'https://budgetbakers.com/privacy'
            break
    }
    return isAppBoard() ? 'https://board.budgetbakers.com/privacy-policy' : walletPolicyUrl // BOARD does not have translated privacy policy
}

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

export const getAppleOAuthId = getValue.bind(null, 'APPLE_OAUTH_ID', 'com.budgetbakers.web-dev.signIn')

export const getBackendUrl = getValue.bind(null, 'BACKEND_URL', 'https://be-dev.budgetbakers.com')

export const getBackendProxyUrl = getValue.bind(null, 'BACKEND_PROXY_URL', 'https://be-proxy-dev.budgetbakers.com')

export const getWalletAppsUrl = getValue.bind(null, 'WALLET_APPS_URL', 'https://web-apps-dev.budgetbakers.com')

export const getDocsUrl = getValue.bind(null, 'DOCS_URL', 'https://docs-dev.budgetbakers.com')

export const getGoogleMapUrl = getValue.bind(null, 'GOOGLE_MAP_URL', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDJzBO6zuc0GQYy4o7TrPuRxzu1GizxPKk')

export const getMixPanelToken = getValue.bind(null, 'MIXPANEL_TOKEN', 'fbf889511bcafa534177dbf1d6ac1f99')

export const getSentryDsn = getValue.bind(null, 'SENTRY_DSN', 'https://d2a1bd358b964d6d8f746717b5776b4f@o71123.ingest.sentry.io/4504632661901312')

export const getSentryEnv = getValue.bind(null, 'SENTRY_ENV', 'local')

export const getGATrackingId = getValue.bind(null, 'GA_TRACKING_ID', 'G-19854BJ95H')

export const getAdwordsSignUpId = getValue.bind(null, 'ADWORDS_SIGN_UP_ID', undefined)

export const getHotjarTrackingId = getNumberValue.bind(null, 'HOTJAR_TRACKING_ID', 1491158)

export const getStorageProxyUrl = getValue.bind(null, 'STORAGE_PROXY_URL', 'https://be-proxy-dev.budgetbakers.com')

export function getGitCommit() {
  return process.env.GIT_COMMIT
}

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

export const getEnabledFeaturesValue = getValue.bind(null, 'ENABLED_FEATURES', '')

export function getEnabledFeatures() {
  const featuresStr = getEnabledFeaturesValue()
  return featuresStr && featuresStr.trim().split(/\s*[,;]\s*/) || []
}

export const getPlacesApiUrl = getValue.bind(null, 'FOURSQUARE_URL', 'https://api.foursquare.com')

export const getPlacesApiClientId = getValue.bind(null, 'FOURSQUARE_CLIENT_ID', 'HCS5PSL2KWKQLNW51KQT1UYKTFKAFMTVKFIXGDSU0UNPHKYY')

export const getFacebookAppID = getValue.bind(null, 'FACEBOOK_APP_ID', '342554855954450')
export const getGoogleAppID = getValue.bind(null, 'GOOGLE_APP_ID', '942078163122-1d7u3v19hg5uhso33qnggp462g26rhpq.apps.googleusercontent.com')

export const getPlacesApiClientSecret = getValue.bind(null, 'FOURSQUARE_CLIENT_SECRET', 'FIN5ADICPBEAMOKFDRZVHI5CZODDGB3OSYBVKBZDOGQGHKVV')

export const getDebugSentryAcceptLocalhost = getBooleanValue.bind(null, 'DEBUG_SENTRY_ACCEPT_LOCALHOST', false)

export const getPaypalKey = getValue.bind(null, 'PAYPAL_KEY', 'AfxyOlW7VRpNzWmHOFG7f1auq1R-lq6vnH1lX0kOoEUo4Y7CXpbzuWPqVq0DfVRzXZDeSQoWDn2A5YVt')

 /*
 __        __                    _             _ _ _ 
 \ \      / /_ _ _ __ _ __ _ __ (_)_ __   __ _| | | |
  \ \ /\ / / _` | '__| '__| '_ \| | '_ \ / _` | | | |
   \ V  V / (_| | |  | |  | | | | | | | | (_| |_|_|_|
    \_/\_/ \__,_|_|  |_|  |_| |_|_|_| |_|\__, (_|_|_)
                                         |___/       
 Don't ever dare to touch this file. The 'generate_enviroment_cfg.sh' script is reading this file and generating the 'environment.cfg' file based on it. 
 
 Particulary, it is looking for the 'getValue.bind(', 'getBooleanValue.bind(', 'getNumberValue.bind(' and reading the ENV variable names from it. IDK why that crazy guy did it this way, but it is what it is. 
 
 Most dangerous thing is if you format this file, it will break the script and the app won't load. If you want to add new ENV variable, respect the file format.
 */

export const getDebugConsoleLogs = getBooleanValue.bind(null, 'DEBUG_CONSOLE_LOGS', true)

export const getDebugReduxLogger = getBooleanValue.bind(null, 'DEBUG_REDUX_LOGGER', true)

export const getDebugReduxDevTools = getBooleanValue.bind(null, 'DEBUG_REDUX_DEVTOOLS', true)

export const getFeedbackTypeformUrl = getValue.bind(null, 'FEEDBACK_TYPEFORM_URL', '')
export const getFeedbackEnabled = getBooleanValue.bind(null, 'FEEDBACK_FORM', false)
/*
 * Docker environment template, please sync it with values above.
 *
Dev
BACKEND_URL=https://be-dev.budgetbakers.com
WALLET_APPS_URL=https://web-apps-dev.budgetbakers.com
DOCS_URL=https://docs-dev.budgetbakers.com
GOOGLE_MAP_URL=https://maps.googleapis.com/maps/api/js?key=AIzaSyCpx3edmyhVqFK6N2ZEqZXEBpfZFC3Vt0M
MIXPANEL_TOKEN=fbf889511bcafa534177dbf1d6ac1f99
SENTRY_DSN=https://d2a1bd358b964d6d8f746717b5776b4f@o71123.ingest.sentry.io/4504632661901312
SENTRY_ENV=dev
ENABLED_FEATURES=ADD_RECORD_REPEAT_FORM, WALLET_LIFE
FOURSQUARE_URL=https://api.foursquare.com
FOURSQUARE_CLIENT_ID=HCS5PSL2KWKQLNW51KQT1UYKTFKAFMTVKFIXGDSU0UNPHKYY
FOURSQUARE_CLIENT_SECRET=FIN5ADICPBEAMOKFDRZVHI5CZODDGB3OSYBVKBZDOGQGHKVV
PAYPAL_KEY=AfxyOlW7VRpNzWmHOFG7f1auq1R-lq6vnH1lX0kOoEUo4Y7CXpbzuWPqVq0DfVRzXZDeSQoWDn2A5YVt
GA_TRACKINIG_ID=G-19854BJ95H
FACEBOOK_APP_ID=342554855954450
GOOGLE_APP_ID=942078163122-ld2kft8cqov3p7cqq7tt1lb0kmbuh3u5.apps.googleusercontent.com
DEBUG_CONSOLE_LOGS=false
DEBUG_REDUX_LOGGER=false
DEBUG_REDUX_DEVTOOLS=false
DEBUG_SENTRY_ACCEPT_LOCALHOST=false
DEBUG_GA=false
DEBUG_SOURCE_MAPS=true
FEEDBACK_FORM=false
FEEDBACK_TYPEFORM_URL=https://demo.typeform.com/to/njdbt5
HOTJAR_TRACKING_ID=1491158
APPLE_OAUTH_ID=com.budgetbakers.web-dev.signIn
*/

/*
Prod
BACKEND_URL=https://app.budgetbakers.com
WALLET_APPS_URL=https://web-apps.budgetbakers.com
DOCS_URL=https://docs.budgetbakers.com
GOOGLE_MAP_URL=https://maps.googleapis.com/maps/api/js?key=AIzaSyCpx3edmyhVqFK6N2ZEqZXEBpfZFC3Vt0M
MIXPANEL_TOKEN=505db41837444662d7e6637cc6755573
SENTRY_DSN=https://d2a1bd358b964d6d8f746717b5776b4f@o71123.ingest.sentry.io/4504632661901312
SENTRY_ENV=prod
ENABLED_FEATURES=BILLING, WALLET_LIFE, ANALYTICS_PIE_CHART, FILTERS
FOURSQUARE_URL=https://api.foursquare.com
FOURSQUARE_CLIENT_ID=HCS5PSL2KWKQLNW51KQT1UYKTFKAFMTVKFIXGDSU0UNPHKYY
FOURSQUARE_CLIENT_SECRET=FIN5ADICPBEAMOKFDRZVHI5CZODDGB3OSYBVKBZDOGQGHKVV
PAYPAL_KEY=AfxyOlW7VRpNzWmHOFG7f1auq1R-lq6vnH1lX0kOoEUo4Y7CXpbzuWPqVq0DfVRzXZDeSQoWDn2A5YVt
GA_TRACKINIG_ID=G-19854BJ95H
FACEBOOK_APP_ID=342551652621437
GOOGLE_APP_ID=942078163122-ld2kft8cqov3p7cqq7tt1lb0kmbuh3u5.apps.googleusercontent.com
DEBUG_CONSOLE_LOGS=false
DEBUG_REDUX_LOGGER=false
DEBUG_REDUX_DEVTOOLS=false
DEBUG_SENTRY_ACCEPT_LOCALHOST=false
DEBUG_GA=false
DEBUG_SOURCE_MAPS=false
FEEDBACK_FORM=false
FEEDBACK_TYPEFORM_URL=https://demo.typeform.com/to/njdbt5
HOTJAR_TRACKING_ID=1490990
APPLE_OAUTH_ID=com.budgetbakers.web.signIn
*/
