import React, { useState } from "react"
import { Modal } from "semantic-ui-react"
import "./NewAppModal.less"
import * as localStorage from "src/backend/db/localStorage"
import { isAppBoard, isProduction } from "src/common/environment"
import { FormattedMessage } from "node_modules/react-intl/dist"
import { PrimaryButton, SecondaryButton } from "src/frontend/components/Buttons/Buttons"

export function redirectToNewApp() {
  window.location.assign(
    isProduction()
      ? isAppBoard()
        ? "https://web-board-new.budgetbakers.com"
        : "https://web-new.budgetbakers.com"
      : isAppBoard()
      ? "https://board-dev-v2.budgetbakers.com"
      : "https://wallet-web-dev-v2.budgetbakers.com",
  )
}

export default function NewAppModal() {
  const [open, setOpen] = useState(true)

  function hide() {
    localStorage.setNewAppPromoHide(true)
    return setOpen(false)
  }

  return (
    <Modal
      size="small"
      open={open}
      onClose={hide}
    >
      <Modal.Content className="feedback-modal">
        <div className="init-page">
          <h2>
            <FormattedMessage id="newapp-promo.modal.main-title.part1" />
            <br />
            <FormattedMessage id="newapp-promo.modal.main-title.part2" />
          </h2>
          <h3>
            <FormattedMessage id="newapp-promo.modal.what.title" />
          </h3>

          <p>
            <FormattedMessage id="newapp-promo.modal.what.bullet1" />
            <br />
            <FormattedMessage id="newapp-promo.modal.what.bullet2" />
            <br />
            <FormattedMessage id="newapp-promo.modal.what.bullet3" />
          </p>

          <h3>
            <FormattedMessage id="newapp-promo.modal.need.title" />
          </h3>
          <p>
            <FormattedMessage id="newapp-promo.modal.need.description" />
          </p>

          <h3>
            <FormattedMessage id="newapp-promo.modal.switch.title" />
          </h3>
          <p>
            <FormattedMessage id="newapp-promo.modal.switch.description" />
          </p>
          <h3>
            <FormattedMessage id="newapp-promo.modal.feedback.title" />
          </h3>
          <p>
            <FormattedMessage id="newapp-promo.modal.feedback.description" />
          </p>

          <PrimaryButton
            onClick={() => {
              localStorage.setNewAppUserAgreed(true)
              redirectToNewApp()
            }}
          >
            <FormattedMessage id="newapp-promo.button-switch" />
          </PrimaryButton>

          <div className="feedback-actions">
            <SecondaryButton onClick={hide}>
              <FormattedMessage id="newapp-promo.button-no" />
            </SecondaryButton>
          </div>
          <p>
            <FormattedMessage id="newapp-promo.modal.thanks" />
          </p>
        </div>
      </Modal.Content>
    </Modal>
  )
}
