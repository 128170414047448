import React, { SyntheticEvent } from 'react'
import 'src/frontend/assets/css/wallet-icons.css'
import 'src/frontend/components/Icons/Icons.less'
import styles from 'src/frontend/components/Icons/Icons.module.less'
import { VIRTUAL_OUT_OF_WALLET_ACCOUNT_TYPE } from 'src/backend/accounts/helpers'
import { AccountType } from 'src/backend/enums'
import { isUndefinedOrNull, mergeClasses } from 'src/common/utils'
import { Icon, SemanticICONS } from 'semantic-ui-react'
import { BasicIconColors, BasicIcons } from 'src/frontend/components/Icons/types'

type AccountIconProps = {
  accountType?: AccountType
  color?: string
  borderRadius?: string
  size?: string
  simple?: boolean
  isInvestments?: boolean
}

AccountIcon.defaultProps = {
  accountType: null,
  size: null,
  borderRadius: null,
  color: "#ccc",
  simple: false,
  isInvestments: false,
}

export function AccountIcon({
  accountType,
  borderRadius,
  color,
  simple,
  size,
  isInvestments,
}: AccountIconProps) {
  const ACCOUNT_ICON_CLASS = {
    [AccountType.GENERAL]: "account-icon account-icon-general",
    [AccountType.CASH]: "account-icon account-icon-cash",
    [AccountType.CURRENT_ACCOUNT]: "account-icon account-icon-current",
    [AccountType.CREDIT_CARD]: "account-icon account-icon-credit",
    [AccountType.SAVING_ACCOUNT]: "account-icon account-icon-saving",
    [AccountType.BONUS]: "account-icon account-icon-bonus",
    [AccountType.INSURANCE]: "account-icon account-icon-insurance",
    [AccountType.INVESTMENT]: "account-icon account-icon-investment",
    [AccountType.LOAN]: "account-icon account-icon-loan",
    [AccountType.MORTGAGE]: "account-icon account-icon-mortgage",
    [AccountType.OVERDRAFT]: "account-icon account-icon-overdraft",
    [AdditionalAccountIcon.INVESTMENT_PORTFOLIO]: "account-icon account-icon-investment-graph",
    [VIRTUAL_OUT_OF_WALLET_ACCOUNT_TYPE]: "account-icon account-icon-oow",
  }
  const sizeProperty = size ? { width: size, height: size, fontSize: size } : {}
  const borderRadiusProperty = borderRadius ? { borderRadius } : {}
  const simpleClass = simple ? " simple" : ""
  const className =
    !isUndefinedOrNull(accountType) && isInvestments
      ? ACCOUNT_ICON_CLASS[AdditionalAccountIcon.INVESTMENT_PORTFOLIO]
      : ACCOUNT_ICON_CLASS[accountType]

  return (
    <div
      className={`icon-square${simpleClass}`}
      style={{ backgroundColor: color, ...sizeProperty, ...borderRadiusProperty }}
    >
      <span className={className} />
    </div>
  )
}

type CategoryIconProps = {
  icon?: string | number
  color: string
  onClick?: (MouseEvent) => void
  className?: string
  size?: string
  hoverIcon?: SemanticICONS
}

CategoryIcon.defaultProps = {
  className: "",
  icon: undefined,
  onClick: undefined,
  size: "2.3rem",
  hoverIcon: undefined,
}

export function CategoryIcon({
  color,
  icon,
  onClick,
  className,
  hoverIcon,
  size,
}: CategoryIconProps) {
  const CATEGORY_ICON_CSS_PREFIX = "category-icon category-icon-"
  const sizeProperty = size ? { width: size, height: size, fontSize: size } : {}

  const hoverClass = hoverIcon ? " hoverable" : ""

  return (
    <div
      className={`icon-circle ${className}${hoverClass}`}
      onClick={onClick}
      style={{ backgroundColor: color, ...sizeProperty }}
    >
      {hoverIcon && (
        <div
          className="hover-icon"
          style={{ ...sizeProperty, fontSize: undefined }}
        >
          <Icon
            fitted
            name={hoverIcon}
          />
        </div>
      )}
      <span className={!isUndefinedOrNull(icon) && CATEGORY_ICON_CSS_PREFIX + icon} />
    </div>
  )
}

type CircleIconProps = {
  color: string
  onClick?: (MouseEvent) => void
  className?: string
  size?: string
}

CircleIcon.defaultProps = {
  className: "",
  size: undefined,
  onClick: undefined,
}

export function CircleIcon({ color, onClick, className, size }: CircleIconProps) {
  const sizeProperty = size ? { width: size, height: size, fontSize: size } : {}

  return (
    <div
      className={`icon-circle ${className}`}
      onClick={onClick}
      style={{ backgroundColor: color, ...sizeProperty }}
    />
  )
}

type FilterIconProps = {
  size: string
}

export function FilterIcon({ size }: FilterIconProps) {
  const sizeProperty = size ? { width: size, height: size, fontSize: size } : {}
  return (
    <div
      className="icon-square"
      style={{ backgroundColor: "#0076FF", ...sizeProperty }}
    >
      <span className="filter-icon" />
    </div>
  )
}

export interface BasicIconProps {
  name: BasicIcons
  size: string
  color?: BasicIconColors
  className?: string
  onClick?: (e: SyntheticEvent) => void
}

BasicIcon.defaultProps = {
  size: undefined,
  color: undefined,
  className: undefined,
  onClick: undefined,
}

export function BasicIcon({ name, className, size, color, onClick }: BasicIconProps) {
  const style = size ? { width: size, height: size, fontSize: size } : undefined
  return (
    <div
      className={mergeClasses(
        styles.basicIcon,
        isUndefinedOrNull(color) && styles.simple,
        name in styles && styles[name],
        color in styles && styles[color],
        className,
      )}
      style={style}
      onClick={onClick}
    />
  )
}

type SquareIconProps = {
  color: string
  onClick?: (MouseEvent) => void
  className?: string
  size?: string
}

SquareIcon.defaultProps = {
  className: "",
  size: undefined,
  onClick: undefined,
}

export function SquareIcon({ color, onClick, className, size }: SquareIconProps) {
  const sizeProperty = size ? { width: size, height: size, fontSize: size } : {}

  return (
    <div
      className={`icon-square ${className}`}
      onClick={onClick}
      style={{ backgroundColor: color, ...sizeProperty }}
    />
  )
}

enum AdditionalAccountIcon {
  INVESTMENT_PORTFOLIO = 1000,
}