import React, { SyntheticEvent } from "react"
import { DropdownProps, Form, Message } from "semantic-ui-react"
import { FormattedMessage, FormattedMessageLabel } from "src/frontend/modules/intl"
import "./AmountInput.less"
import { Id } from "src/types/CouchDb"
import { Currency } from "src/types/Currency"

interface Props {
  amount: number
  amountName: string
  currencyName: string
  currencyId: Id
  recordTypeClass?: "transfer" | "income" | "expense" | ""
  currencies?: Currency[]
  amountError?: string
  amountDisabled?: boolean
  currencyError?: string
  currencyDisabled?: boolean
  autoFocus?: boolean

  onCurrencyChange: (e: SyntheticEvent, data: DropdownProps) => void
  onAmountChange: Function
}

interface State {
  amountString: string
}

const AMOUNT_STRING_PATTERN = /^(\d+[.,]?(\d{0,2})?|[.,]\d{0,2})$/

class AmountInput extends React.Component<Props, State> {
  static defaultProps = {
    amountError: undefined,
    currencyError: undefined,
    amountDisabled: false,
    autoFocus: false,
    currencyDisabled: false,
    currencies: [],
    recordTypeClass: "",
  }

  state = {
    amountString: this.props.amount
      ? parseFloat((this.props.amount / 100).toFixed(2)).toLocaleString()
      : "",
  }

  static formatInputValue(value) {
    const formattedValue = value.match(AMOUNT_STRING_PATTERN)
    return ((formattedValue && formattedValue[0]) || "").replace(",", ".")
  }

  handleChangeAmount = (event, { value }) => {
    // don't clear the input when user starts typing number in the format .50
    if (value === "," || value === ".") {
      this.setState(() => ({ amountString: value }))
    } else if (AmountInput.formatInputValue(value) || value === "") {
      this.props.onAmountChange(event, {
        name: this.props.amountName,
        // Todo create some util functions to convert from/to couch amount format (* 100)
        value: Math.round(parseFloat(AmountInput.formatInputValue(value) || 0) * 100),
      })
      this.setState(() => ({ amountString: AmountInput.formatInputValue(value) }))
    }
  }

  componentDidUpdate(prevProps) {
    // prevent setState if props have't changed
    if (this.props.amount !== prevProps.amount) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => ({
        amountString: this.props.amount
          ? parseFloat((this.props.amount / 100).toFixed(2)).toString()
          : "",
      }))
    }
  }

  render() {
    const {
      amountName,
      currencyName,
      currencyId,
      currencies,
      amountError,
      amountDisabled,
      currencyError,
      currencyDisabled,
      autoFocus,
      recordTypeClass,
      onCurrencyChange,
    } = this.props

    const { amountString } = this.state

    const currencyOptions = currencies.map((currency) => ({
      text: currency.code,
      value: currency._id,
      key: currency._id,
    }))

    const hasAmountError = !!amountError
    const hasCurrencyError = !!currencyError

    return (
      <div className="field">
        <Form.Group>
          <Form.Input
            className={`field-amount ${recordTypeClass}`}
            label={<FormattedMessageLabel id="record.form.amount" />}
            name={amountName}
            value={amountString}
            placeholder={!amountDisabled ? "0" : ""}
            error={hasAmountError}
            disabled={amountDisabled}
            width="10"
            autoFocus={autoFocus}
            onChange={this.handleChangeAmount}
          />
          <Form.Dropdown
            className="field-currency small-select"
            label={<FormattedMessageLabel id="record.form.currency" />}
            name={currencyName}
            value={currencyId}
            options={currencyOptions}
            error={hasCurrencyError}
            disabled={true}
            width="6"
            selection
            onChange={onCurrencyChange}
          />
        </Form.Group>

        {hasAmountError && (
          <Message
            className="error-message"
            error
            content={<FormattedMessage id={amountError} />}
          />
        )}

        {hasCurrencyError && (
          <Message
            className="error-message"
            error
            content={<FormattedMessage id={currencyError} />}
          />
        )}
      </div>
    )
  }
}

export default AmountInput
