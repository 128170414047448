import React from 'react'
import styles from './UserIconItem.module.less'
import { BasicIcon } from 'src/frontend/components/Icons/Icons'
import { BasicIconColors, BasicIcons } from "src/frontend/components/Icons/types"

interface Props {
  icon: BasicIcons
  text: string | React.ReactNode
}

export default function UserIconItem({ icon, text }: Props) {
  return (
    <div className={styles.item}>
      <BasicIcon
        name={icon}
        size="20px"
      />
      <div>{text}</div>
    </div>
  )
}
